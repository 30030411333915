define("robo-derek/torii-providers/google-custom", ["exports", "torii/providers/google-oauth2"], function (_exports, _googleOauth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _googleOauth.default.extend({});

  _exports.default = _default;
});