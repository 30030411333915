define("robo-derek/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SvC92VzP",
    "block": "{\"symbols\":[],\"statements\":[[5,\"navbar\",[],[[\"@invalidate\",\"@session\"],[[23,0,[\"invalidate\"]],[23,0,[\"session\"]]]]],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"hero is-fullheight-with-navbar application-hero\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"hero-body\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"hero-foot\"],[8],[0,\"\\n\\t\\t\"],[5,\"footer\",[],[[],[]]],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "robo-derek/templates/application.hbs"
    }
  });

  _exports.default = _default;
});