define("robo-derek/templates/authenticated/pregen-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SRKkPAto",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container pregen-report\"],[8],[0,\"\\n\\t\"],[7,\"form\",false],[3,\"on\",[\"submit\",[23,0,[\"searchMid\"]]]],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"field has-addons\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"control\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[5,\"input\",[[12,\"class\",\"input\"],[12,\"placeholder\",\"Enter MID\"]],[[\"@value\"],[[23,0,[\"mid\"]]]]],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"control\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"button\",true],[10,\"class\",\"button is-info\"],[10,\"type\",\"submit\"],[8],[0,\"\\n\\t\\t\\t\\t\\tSearch for MID\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "robo-derek/templates/authenticated/pregen-report.hbs"
    }
  });

  _exports.default = _default;
});