define("robo-derek/helpers/array-contains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayContains = arrayContains;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * Checks if array contains element.
   *
   * @param    {Array}  array - array of items
   * @param    {Object} item  - item to check
   * @param    {String} key   - optional key in source array
   * @returns  {Boolean} check result
   */
  function arrayContains(_ref) {
    var _ref2 = _slicedToArray(_ref, 3),
        array = _ref2[0],
        item = _ref2[1],
        key = _ref2[2];

    if (!array) {
      return false;
    }

    var collection = key ? array.map(function (i) {
      return Ember.get(i, key);
    }) : array;
    return collection.includes(item);
  }

  var _default = Ember.Helper.helper(arrayContains);

  _exports.default = _default;
});