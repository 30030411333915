define("robo-derek/helpers/check-environment", ["exports", "robo-derek/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isProductionEnvironment = void 0;

  var isProductionEnvironment = function isProductionEnvironment() {
    return _environment.default.environment === 'production';
  };

  _exports.isProductionEnvironment = isProductionEnvironment;
});