define("robo-derek/components/result-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button {{on "click" @action}}
    type="button"
    class="button is-link {{if (eq @state "loading") 'is-loading'}}"
    disabled="{{if (eq @state "loading") 'DISABLED'}}">
    {{@text}}
  </button>
  
  
  
  {{#if (eq @state "success")}}
    <br />
    <br />
    <span class="has-text-success ml-4 mt-2">
      <i class="fas fa-check"></i> {{if @successMessage @successMessage 'Success' }}
    </span>
  {{/if}}
  
  */
  {
    id: "90Coq70j",
    block: "{\"symbols\":[\"@successMessage\",\"@state\",\"@action\",\"@text\"],\"statements\":[[7,\"button\",false],[12,\"class\",[29,[\"button is-link \",[28,\"if\",[[28,\"eq\",[[23,2,[]],\"loading\"],null],\"is-loading\"],null]]]],[12,\"disabled\",[29,[[28,\"if\",[[28,\"eq\",[[23,2,[]],\"loading\"],null],\"DISABLED\"],null]]]],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[23,3,[]]]],[8],[0,\"\\n  \"],[1,[23,4,[]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\\n\"],[4,\"if\",[[28,\"eq\",[[23,2,[]],\"success\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"br\",true],[8],[9],[0,\"\\n  \"],[7,\"br\",true],[8],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"has-text-success ml-4 mt-2\"],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"fas fa-check\"],[8],[9],[0,\" \"],[1,[28,\"if\",[[23,1,[]],[23,1,[]],\"Success\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    meta: {
      moduleName: "robo-derek/components/result-button.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});