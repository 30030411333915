define("robo-derek/templates/authenticated/webhooks/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rz3bTD/E",
    "block": "{\"symbols\":[],\"statements\":[[7,\"article\",true],[10,\"class\",\"message error is-danger\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"message-header\"],[8],[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[0,\"Location not found\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"message-body\"],[8],[0,\"\\n\\t\\tThe requested merchant ID did not return a location. Please double-check your input and try again.\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "robo-derek/templates/authenticated/webhooks/error.hbs"
    }
  });

  _exports.default = _default;
});