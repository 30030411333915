define("robo-derek/components/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <footer class="footer">
  	<div class="content has-text-centered">
  		Robo Derek... because we're bad at naming things.
  	</div>
  </footer>
  
  */
  {
    id: "RlaUh+1D",
    block: "{\"symbols\":[],\"statements\":[[7,\"footer\",true],[10,\"class\",\"footer\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"content has-text-centered\"],[8],[0,\"\\n\\t\\tRobo Derek... because we're bad at naming things.\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "robo-derek/components/footer.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});