define("robo-derek/authenticators/torii", ["exports", "ember-simple-auth/authenticators/torii", "robo-derek/config/environment"], function (_exports, _torii, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = _torii.default.extend({
    torii: Ember.inject.service(),
    authenticate: function authenticate() {
      var tokenExchangeUri = _environment.default.torii.providers['google-oauth2'].tokenExchangeUri;
      return this._super.apply(this, arguments).then(function (data) {
        return fetch(tokenExchangeUri, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }).then(function _callee(response) {
          var message, responseBody;
          return regeneratorRuntime.async(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  if (!response.ok) {
                    _context.next = 2;
                    break;
                  }

                  return _context.abrupt("return", response.json());

                case 2:
                  message = null;
                  _context.next = 5;
                  return regeneratorRuntime.awrap(response.json());

                case 5:
                  responseBody = _context.sent;

                  if (typeof responseBody.error === 'string') {
                    message = responseBody.error;
                  } else if (_typeof(responseBody.error) === 'object') {
                    message = responseBody.error.message;
                  } else if (typeof responseBody.message === 'string') {
                    message = responseBody.message;
                  } else {
                    message = 'Unexpected error occurred';
                  }

                  throw new Error(message);

                case 8:
                case "end":
                  return _context.stop();
              }
            }
          });
        }).then(function (response) {
          return {
            'access_token': response.access_token,
            groups: response.groups,
            provider: data.provider
          };
        });
      });
    }
  });

  _exports.default = _default;
});